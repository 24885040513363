<template>
    <div class="warp">
        <div class="platformStatic">
            <div class="staticList" style="margin-bottom: 25px">
                <ul class="staticCon">
                    <li>
                        <p class="staticTitle">
                            设备数
                        </p>
                        <p class="staticNum" @click="gotoEquip">
                            {{ info.deviceCount || 0 }}
                        </p>
                    </li>
                    <li>
                        <p class="staticTitle">
                            产品数
                        </p>
                        <p class="staticNum" @click="gotoProduct">
                            {{ info.productCount || 0 }}
                        </p>
                    </li>
                    <li>
                        <p class="staticTitle">
                            项目数
                        </p>
                        <p class="staticNum" @click="gotoProject">
                            {{ info.projectCount || 0 }}
                        </p>
                    </li>
                    <li>
                        <p class="staticTitle">
                            昨日消息数
                            <a-tooltip placement="top">
                                <template slot="title">
                                    <span>数据统计截止昨日24点</span>
                                </template>
                                <a-icon style="cursor: pointer" type="info-circle" />
                            </a-tooltip>
                        </p>
                        <p class="staticNum" @click="undeveloped">
                            {{ info.yesterdayMsgCount || 0 }}
                        </p>
                    </li>
                    <li>
                        <p class="staticTitle">
                            昨日API调用数
                            <a-tooltip placement="top">
                                <template slot="title">
                                    <span>数据统计截止昨日24点</span>
                                </template>
                                <a-icon style="cursor: pointer" type="info-circle" />
                            </a-tooltip>
                        </p>
                        <p class="staticNum" @click="undeveloped">
                            {{ info.yesterdayApiCount || 0 }}
                        </p>
                    </li>
                </ul>
            </div>
            <div class="staticList">
                <!-- <p class="title" style="border-bottom: 1px solid #F0F0F0; margin-bottom: 20px">
                    流程指南
                </p>
                <a-tabs tab-position="left">
                    <a-tab-pane key="1" tab="标准协议接入">
                        <img src="@/assets/img/stanag.png" width="600">
                    </a-tab-pane>
                    <a-tab-pane key="2" tab="泛协议接入" force-render>
                        <img src="@/assets/img/Panagree.png" width="600">
                    </a-tab-pane>
                    <a-tab-pane key="3" tab="SDK开发部署">
                        <img src="@/assets/img/SDKdevelop.png" width="600">
                    </a-tab-pane>
                </a-tabs> -->
                <Statistical/>
            </div>
        </div>
        <div class="recordWrap">
            <div class="recordBox" style="margin-bottom: 25px">
                <p class="title">
                    常用入口
                </p>
                <ul class="recordList">
                    <li @click="addProduct">
                        添加产品
                    </li>
                    <li @click="addEquip">
                        添加设备
                    </li>
                    <li @click="addProject">
                        添加项目
                    </li>
                    <li @click="onGroup">
                        分组管理
                    </li>
                    <li @click="onLog">
                        设备日志
                    </li>
                    <li @click="undeveloped">
                        <a-icon type="plus" />
                    </li>
                </ul>
            </div>
            <div class="recordBox">
                <div>
                    <p class="title">
                        最近访问
                    </p>
                    <ul class="recordItem" style="border-bottom: 1px solid #F0F0F0;">
                        <li class="recordItemTitle">
                            最近访问项目
                        </li>
                        <!-- <router-link :to="visited.projectUrl || ''">
                            <li class="recordItems">
                                {{ visited.projectName }}
                            </li>
                        </router-link> -->
                    </ul>
                    <ul class="recordItem">
                        <li class="recordItemTitle">
                            最近访问产品
                        </li>
                        <router-link :to="visited.url || ''">
                            <li class="recordItems">
                                {{ visited.name }}
                            </li>
                        </router-link>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getStatistics } from "@/api/generalization"
import { getLocalStorage } from "@/utils/localstorage"
import Statistical from './components/Statistical.vue'
export default {
    name: "Index",
    components: {
        Statistical
    },
    data() {
        return {
            info: {},
            visited: {
                name: '',
                url: '',
                projectUrl: '',
                projectName: ''
            }
        }
    },
    created() {
        getStatistics().then((res) => {
            this.info = res.data
        }).catch(error => {
            console.log(error)
        })
        this.visited.name = getLocalStorage('name')
        this.visited.url = getLocalStorage('url')
        // this.visited.projectName = getLocalStorage('projectName')
        // this.visited.projectUrl = getLocalStorage('projectUrl')
    },
    methods: {
        // 点击设备数进入设备列表页
        gotoEquip() {
            this.$router.push('/equip-list')
        },
        // 点击产品数进入产品列表页
        gotoProduct() {
            this.$router.push('/product-list')
        },
        // 点击项目数进入项目概况页
        gotoProject() {
            this.$router.push('/project-manage')
        },
        // 点击添加产品
        addProduct () {
            this.$router.push({path:'/product-list', query: {fromPage:'home'}})
        },
        // 点击添加设备
        addEquip () {
            this.$router.push({path:'/equip-list', query: {fromPage:'home'}})
        },
        // 点击添加项目
        addProject () {
            this.$router.push({path:'/project-manage', query: {fromPage:'home'}})
        },
        // 点击分组管理
        onGroup () {
            this.$router.push('/equip-management')
        },
        // 点击设备日志
        onLog() {
            this.$router.push('/equip-log')
        },
        undeveloped() {
            this.$message.warning('功能暂未开发！')
        }
    }
}
</script>

<style lang="scss" scoped>
    .warp {
        display: flex;
        padding: 25px;
        background: #F0F0F7;
    }
    .platformStatic {
        flex: 1;
        margin-right: 25px;
    }
    .staticList {
        background: #FFFFFF;
        border: 1px solid #F0F0F0;
        border-radius: 8px;
        color: #818181;
        .title {
            color: #4D4F5C;
            font-size: 16px;
            font-weight: bold;
            padding: 16px;
            margin: 0;
        }
        .staticCon {
            display: flex;
            margin: 0;
            color: #818181;
            li {
                height: 129px;
                border: 1px solid #F0F0F0;
                border-right: none;
                padding: 16px 0 16px 16px;
                flex: 1;
                background-size: 70px;
                background-repeat: no-repeat;
                background-position: right bottom;
                border-bottom: none;
                .staticNum {
                    font-weight: bold;
                    font-size: 32px;
                    color: #4D4F5C;
                    cursor: pointer;
                }
            }
            li:nth-of-type(1) {
                border-left: none;
                background-image: url("~@/assets/img/equip.png");
            }
            li:nth-of-type(2) {
                background-image: url("~@/assets/img/product.png");
            }
            li:nth-of-type(3) {
                background-image: url("~@/assets/img/item.png");
            }
            li:nth-of-type(4) {
                background-image: url("~@/assets/img/info.png");
                background-size: 60px;
            }
            li:last-of-type {
                background-image: url("~@/assets/img/API.png");
                margin-right: 0;
                border-right: none;
            }
        }
        ::v-deep .ant-tabs-tabpane img{
            margin-bottom: 20px;
        }
    }
    .recordWrap {
        width: 320px;
        .recordBox {
            background: #FFFFFF;
            border: 1px solid #F0F0F0;
            border-radius: 8px;
            color: #4D4F5C;
            .title {
                font-size: 16px;
                font-weight: bold;
                padding: 16px;
                border-bottom: 1px solid #F0F0F0;
            }
        }
        .recordList {
            display: flex;
            flex-wrap: wrap;
            padding: 0 16px;
            li {
                padding: 12px 0;
                margin-right: 18px;
                text-align: center;
                width: 29%;
                font-weight: bold;
                letter-spacing: 0.2px;
                color: #3F8AE0;
                cursor: pointer;
                margin-bottom: 16px;
                border-radius: 8px;
                background: rgba(0, 28, 61, 0.05);
            }
            li:nth-of-type(3n) {
                margin-right: 0;
            }
        }
        .recordItem {
            padding-left: 16px;
            color: #4D4F5C;
            height: 200px;
        }
        .recordItemTitle {
            color: #818181;
            margin-bottom: 10px;
        }
        .recordItems {
            cursor: pointer;
            color: #333;
        }
    }
</style>
