<template>
  <div class="statistical">
    <div class="left-chart">
      <div class="pieChart" ref="pieChart"></div>
      <div class="histogram" ref="histogram"></div>
    </div>
    <div class="right-chart">
      <div class="lineChart" ref="lineChart"></div>
    </div>
  </div>
</template>

<script>
import { initPieChart, initHistogram, initLineChart } from "./methods/echarts";
export default {
  data() {
    return {
      pieChartData: {
        list: [
          { value: 60, name: "在线设备数" },
          { value: 20, name: "离线设备数" },
          { value: 10, name: "故障设备数" },
        ],
      },
      histogramData: {
        list: [
          {
            name: "违章停车",
            value: 120,
            itemStyle: {
              color: "#5a6fc0",
            },
          },
          {
            name: "人工缺勤",
            value: 200,
            itemStyle: {
              color: "#a90000",
            },
          },
          {
            name: "水浸报警",
            value: 150,
            itemStyle: {
              color: "#5a6fc0",
            },
          },
          {
            name: "危险气体",
            value: 80,
            itemStyle: {
              color: "#5a6fc0",
            },
          },
          {
            name: "高温报警",
            value: 70,
            itemStyle: {
              color: "#5a6fc0",
            },
          },
          {
            name: "虫害报警",
            value: 110,
            itemStyle: {
              color: "#5a6fc0",
            },
          },
          {
            name: "电涌",
            value: 130,
            itemStyle: {
              color: "#5a6fc0",
            },
          },
        ],
      },
    };
  },
  mounted() {
    this.getPieChart();
    this.getHistogram();
    this.getLineChart();
  },
  methods: {
    //生成一个饼状图
    getPieChart() {
      const el = this.$refs.pieChart;
      initPieChart(el, this.pieChartData);
    },
    //生成一个柱状图
    getHistogram() {
      const el = this.$refs.histogram;
      initHistogram(el, this.histogramData);
    },
    //生成一个柱状图
    getLineChart() {
      const el = this.$refs.lineChart;
      initLineChart(el);
    },
  },
};
</script>

<style scoped lang="scss">
.pieChart {
  width: 100%;
  height: 200px;
}
.histogram {
  width: 100%;
  height: 400px;
}
.lineChart {
  width: 100%;
  height: 600px;
}
.statistical {
  display: flex;
  .left-chart{
      width: 40%;
  }
  .right-chart {
      width: 60%;
  }
}
</style>
