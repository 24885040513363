// 设置字符串类型的缓存数据
export function setLocalStorage(key, value) {
    localStorage.setItem(key, value)
}

// 读取字符串类型的缓存数据
export function getLocalStorage(key) {
    return localStorage.getItem(key)
}

// 设置对象/数组类型的缓存数据
export function setObjLocalStorage(key, value) {
    localStorage.setItem(key, JSON.stringify(value))
}

// 读取对象/数组类型的缓存数据
export function getObjLocalStorage(key) {
    return JSON.parse(localStorage.getItem(key))
}

// 删除缓存数据
export function removeLocalStorage(key) {
    localStorage.removeItem(key)
}
