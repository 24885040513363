var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "warp" }, [
    _c("div", { staticClass: "platformStatic" }, [
      _c(
        "div",
        { staticClass: "staticList", staticStyle: { "margin-bottom": "25px" } },
        [
          _c("ul", { staticClass: "staticCon" }, [
            _c("li", [
              _c("p", { staticClass: "staticTitle" }, [_vm._v(" 设备数 ")]),
              _c(
                "p",
                { staticClass: "staticNum", on: { click: _vm.gotoEquip } },
                [_vm._v(" " + _vm._s(_vm.info.deviceCount || 0) + " ")]
              ),
            ]),
            _c("li", [
              _c("p", { staticClass: "staticTitle" }, [_vm._v(" 产品数 ")]),
              _c(
                "p",
                { staticClass: "staticNum", on: { click: _vm.gotoProduct } },
                [_vm._v(" " + _vm._s(_vm.info.productCount || 0) + " ")]
              ),
            ]),
            _c("li", [
              _c("p", { staticClass: "staticTitle" }, [_vm._v(" 项目数 ")]),
              _c(
                "p",
                { staticClass: "staticNum", on: { click: _vm.gotoProject } },
                [_vm._v(" " + _vm._s(_vm.info.projectCount || 0) + " ")]
              ),
            ]),
            _c("li", [
              _c(
                "p",
                { staticClass: "staticTitle" },
                [
                  _vm._v(" 昨日消息数 "),
                  _c(
                    "a-tooltip",
                    { attrs: { placement: "top" } },
                    [
                      _c("template", { slot: "title" }, [
                        _c("span", [_vm._v("数据统计截止昨日24点")]),
                      ]),
                      _c("a-icon", {
                        staticStyle: { cursor: "pointer" },
                        attrs: { type: "info-circle" },
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "p",
                { staticClass: "staticNum", on: { click: _vm.undeveloped } },
                [_vm._v(" " + _vm._s(_vm.info.yesterdayMsgCount || 0) + " ")]
              ),
            ]),
            _c("li", [
              _c(
                "p",
                { staticClass: "staticTitle" },
                [
                  _vm._v(" 昨日API调用数 "),
                  _c(
                    "a-tooltip",
                    { attrs: { placement: "top" } },
                    [
                      _c("template", { slot: "title" }, [
                        _c("span", [_vm._v("数据统计截止昨日24点")]),
                      ]),
                      _c("a-icon", {
                        staticStyle: { cursor: "pointer" },
                        attrs: { type: "info-circle" },
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "p",
                { staticClass: "staticNum", on: { click: _vm.undeveloped } },
                [_vm._v(" " + _vm._s(_vm.info.yesterdayApiCount || 0) + " ")]
              ),
            ]),
          ]),
        ]
      ),
      _c("div", { staticClass: "staticList" }, [_c("Statistical")], 1),
    ]),
    _c("div", { staticClass: "recordWrap" }, [
      _c(
        "div",
        { staticClass: "recordBox", staticStyle: { "margin-bottom": "25px" } },
        [
          _c("p", { staticClass: "title" }, [_vm._v(" 常用入口 ")]),
          _c("ul", { staticClass: "recordList" }, [
            _c("li", { on: { click: _vm.addProduct } }, [_vm._v(" 添加产品 ")]),
            _c("li", { on: { click: _vm.addEquip } }, [_vm._v(" 添加设备 ")]),
            _c("li", { on: { click: _vm.addProject } }, [_vm._v(" 添加项目 ")]),
            _c("li", { on: { click: _vm.onGroup } }, [_vm._v(" 分组管理 ")]),
            _c("li", { on: { click: _vm.onLog } }, [_vm._v(" 设备日志 ")]),
            _c(
              "li",
              { on: { click: _vm.undeveloped } },
              [_c("a-icon", { attrs: { type: "plus" } })],
              1
            ),
          ]),
        ]
      ),
      _c("div", { staticClass: "recordBox" }, [
        _c("div", [
          _c("p", { staticClass: "title" }, [_vm._v(" 最近访问 ")]),
          _vm._m(0),
          _c(
            "ul",
            { staticClass: "recordItem" },
            [
              _c("li", { staticClass: "recordItemTitle" }, [
                _vm._v(" 最近访问产品 "),
              ]),
              _c("router-link", { attrs: { to: _vm.visited.url || "" } }, [
                _c("li", { staticClass: "recordItems" }, [
                  _vm._v(" " + _vm._s(_vm.visited.name) + " "),
                ]),
              ]),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "ul",
      {
        staticClass: "recordItem",
        staticStyle: { "border-bottom": "1px solid #F0F0F0" },
      },
      [_c("li", { staticClass: "recordItemTitle" }, [_vm._v(" 最近访问项目 ")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }