import Axios from "axios";
import { message } from "ant-design-vue";
import { doLogout, getToken } from "sso-login-logic";
import router from "@/router";
// 创建axios实例
const server = Axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // withCredentials: true,
  timeout: 50000, // 请求超时时间
});

// 添加请求拦截器
server.interceptors.request.use(
  (config) => {
    // /** 设置默认token */
    // let token = getToken('token')
    // let backstageToken = localStorage.getItem('token')
    // // 请求头
    // if(token) {
    //     config.headers['token'] = token
    // }else{
    //     config.headers['token'] = backstageToken
    // }
    if (config && config.headers && !config.headers.satoken) {
      config.headers.satoken = getToken();
    }
    return config;
  },
  (error) => {
    message.error(error.errorMsg);
    return Promise.reject(error);
  }
);

// 添加响应拦截器
server.interceptors.response.use(
  (response) => {
    if (response.status === 401 || response.data.errorCode === 401) {
      message.error("登录信息已过期");
      doLogout(process.env.VUE_APP_LOGINBASE_URL);
    }
    // 请求成功返回的数据
    let data = response.data;
    if (data.errorCode === 200) {
      // 成功
      return data;
    } else {
      // 失败
      message.error(data.errorMsg);
      return Promise.reject("error");
    }
  },
  (error) => {
    if (error.response.status === 401) {
      message.error("用户没有访问权限,需要进行身份认证!");
      router.push("/");
    } else {
      message.error(error.response.data.errorMsg);
      return Promise.reject(error);
    }
  }
);

export default server;
