var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "statistical" }, [
    _c("div", { staticClass: "left-chart" }, [
      _c("div", { ref: "pieChart", staticClass: "pieChart" }),
      _c("div", { ref: "histogram", staticClass: "histogram" }),
    ]),
    _c("div", { staticClass: "right-chart" }, [
      _c("div", { ref: "lineChart", staticClass: "lineChart" }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }